import { CometChat } from '@cometchat-pro/chat';
import { rollbar } from '../rollbar';
import { config } from './config';
import engineClient from '../wilco-engine/engine-client';
import CometListeners from './CometListeners';

export const LOGIN_STATE = {
  LOADING: 'loading',
  LOGGED_IN: 'logged_in',
  ERROR: 'error',
};

const appSettings = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(config.APP_REGION)
  .build();

CometChat.init(config.APP_ID, appSettings);
export const cometListeners = new CometListeners();

const performCometRequest = async req => {
  try {
    return await req();
  } catch (e) {
    rollbar.info(Error().stack);
    rollbar.warn('error performing cometchat request', e);
    throw e;
  }
};

export const login = async (expectedId, token = null) => {
  if (expectedId) {
    const user = await CometChat.getLoggedinUser();
    if (user?.uid === expectedId) {
      return LOGIN_STATE.LOGGED_IN;
    } else {
      if (user?.uid) {
        await logout();
      }
      await loginWithToken(token || (await getLoginToken()));
    }
  }
};

export const getLoginToken = async () => {
  const { token } = await engineClient.createChatToken();
  return token;
};

export const loginWithToken = async token => {
  await performCometRequest(async () => {
    await CometChat.login(token);
  });
};

export const logout = async () => {
  try {
    await CometChat.logout();
  } catch (e) {
    if (e.code !== 'USER_NOT_LOGED_IN') {
      rollbar.warn('failed logging out user from cometchat');
    }
  }
};

export const getLoggedInUser = async () => {
  return await CometChat.getLoggedinUser();
};

export const getUser = async userId => {
  return await performCometRequest(async () => {
    if (!userId) return;
    return await CometChat.getUser(userId);
  });
};

export const getChannelList = async () => {
  return await performCometRequest(() => {
    return new CometChat.GroupsRequestBuilder()
      .setLimit(config.INITIAL_CHANNELS_LIMIT)
      .joinedOnly(true)
      .build()
      .fetchNext();
  });
};

export const getFriendList = async limit => {
  return await performCometRequest(() => {
    return new CometChat.UsersRequestBuilder()
      .setLimit(limit)
      .friendsOnly(true)
      .build()
      .fetchNext();
  });
};

export const getFriend = async userId => {
  return await performCometRequest(() => {
    return new CometChat.getUser(userId);
  });
};

export const getConversationFetcher = conversationId => {
  if (!conversationId) return;
  return new CometChat.MessagesRequestBuilder()
    .setLimit(config.INITIAL_CONVERSATION_MESSAGES_LIMIT)
    .setUID(conversationId)
    .build();
};

export const getInitialConversations = async () => {
  return await performCometRequest(() => {
    return new CometChat.ConversationsRequestBuilder()
      .setLimit(config.INITIAL_FRIENDS_LIMIT + config.INITIAL_CHANNELS_LIMIT)
      .build()
      .fetchNext();
  });
};

export const getConversationUnreadCount = async userId => {
  return await performCometRequest(() => {
    return CometChat.getUnreadMessageCountForUser(userId);
  });
};

export const markAsDelivered = async message => {
  return await performCometRequest(() => {
    if (!message.deliveredAt) {
      CometChat.markAsDelivered(message);
    }
  });
};

export const markConversationRead = async lastMessage => {
  return await performCometRequest(() => {
    return CometChat.markAsRead(lastMessage);
  });
};

export const sendUserTextMessage = async (
  receiverId,
  message,
  metadata,
  category
) => {
  const textMessage = new CometChat.TextMessage(
    receiverId,
    message,
    CometChat.RECEIVER_TYPE.USER
  );
  const stepId = metadata?.context?.stepId;
  const userQuestId = metadata?.context?.userQuestId;
  const tags =
    userQuestId && stepId
      ? [`userQuestId:${userQuestId}`, `stepId:${stepId}`]
      : [];

  if (metadata) {
    textMessage.setMetadata(metadata);
  }
  if (category) {
    textMessage.setCategory(category);
  }
  if (stepId) {
    textMessage.setTags(tags);
  }

  return await performCometRequest(() => {
    return CometChat.sendMessage(textMessage);
  });
};
