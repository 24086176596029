import { useContext, useEffect, useCallback } from 'react';
import { AppContext, APP_ACTIONS } from './appContext';
import engineClient from './wilco-engine/engine-client';

export const FETCH_ACTIONS = {
  FETCH_QUEST_BY_PRIMARY_OR_ID: 'FETCH_QUEST_BY_PRIMARY_OR_ID',
  FETCH_ACTIVE_QUESTS: 'FETCH_ACTIVE_QUESTS',
  FETCH_USER_INFORMATION: 'FETCH_USER_INFORMATION',
  FETCH_USER_GROUPS: 'FETCH_USER_GROUPS',
  FETCH_PENDING_NOTIFICATIONS: 'FETCH_PENDING_NOTIFICATIONS',
  FETCH_HINT_PRICE: 'FETCH_HINT_PRICE',
  FETCH_SKILLS: 'FETCH_SKILLS',
  FETCH_USER_AUTHORED_QUESTS: 'FETCH_USER_AUTHORED_QUESTS',
  FETCH_TEAM_INFO: 'FETCH_TEAM_INFO',
  FETCH_CERTIFICATE: 'FETCH_CERTIFICATE',
  FETCH_COLLABORATORS: 'FETCH_COLLABORATORS',
  FETCH_APPROVED_COLLABORATORS: 'FETCH_APPROVED_COLLABORATORS',
  FETCH_IN_REVIEW_QUESTS: 'FETCH_IN_REVIEW_QUESTS',
  FETCH_USER_NEXT_QUESTS: 'FETCH_USER_NEXT_QUESTS',
};

const fetchRequest = async (fetch, client) => {
  switch (fetch.action) {
    case FETCH_ACTIONS.FETCH_QUEST_BY_PRIMARY_OR_ID: {
      return await client.getQuestByPrimaryOrId(fetch.data.questId);
    }
    case FETCH_ACTIONS.FETCH_ACTIVE_QUESTS: {
      return await client.getUserActiveQuests();
    }
    case FETCH_ACTIONS.FETCH_USER_INFORMATION: {
      return await client.getUser();
    }
    case FETCH_ACTIONS.FETCH_USER_GROUPS: {
      return await client.getUserGroups();
    }
    case FETCH_ACTIONS.FETCH_PENDING_NOTIFICATIONS: {
      return await client.getUserNotifications();
    }
    case FETCH_ACTIONS.FETCH_HINT_PRICE: {
      return await client.getHintPrice();
    }
    case FETCH_ACTIONS.FETCH_SKILLS: {
      return await client.getSkills();
    }
    case FETCH_ACTIONS.FETCH_USER_AUTHORED_QUESTS: {
      return await client.getUserAuthoredQuests();
    }
    case FETCH_ACTIONS.FETCH_COLLABORATORS: {
      return await client.getCollaborators();
    }
    case FETCH_ACTIONS.FETCH_APPROVED_COLLABORATORS: {
      return await client.getApprovedCollaborators();
    }
    case FETCH_ACTIONS.FETCH_CERTIFICATE: {
      return await client.getCertificate(fetch.data.certificateId);
    }
    case FETCH_ACTIONS.FETCH_IN_REVIEW_QUESTS: {
      return await client.getInReviewQuests();
    }
    case FETCH_ACTIONS.FETCH_USER_NEXT_QUESTS: {
      return await client.getNextQuests();
    }

    default:
  }
};

function Fetcher() {
  const {
    state: { fetch },
    dispatch,
  } = useContext(AppContext);

  const fetchAction = useCallback(
    fetch => {
      let canceled = false;
      dispatch({
        type: APP_ACTIONS.FETCH_STARTED,
        payload: { fetch },
      });
      fetchRequest(fetch, engineClient)
        .then(data => {
          if (!canceled) {
            dispatch({
              type: APP_ACTIONS.FETCH_FINISHED,
              payload: { action: fetch.action, data },
            });
          } else {
            dispatch({
              type: APP_ACTIONS.FETCH_CANCELED,
              payload: { action: fetch.action },
            });
          }
        })
        .catch(error => {
          dispatch({
            type: APP_ACTIONS.FETCH_ERROR,
            payload: { action: fetch.action, error },
          });
        });

      return () => (canceled = true);
    },
    [dispatch]
  );

  useEffect(() => {
    fetch.pending?.map(action => fetchAction(action));
  }, [fetch.pending, fetchAction]);

  return null;
}

export default Fetcher;
