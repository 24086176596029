import { useContext, useEffect } from 'react';
import { AppContext, SOCKET_ACTIONS } from './appContext';
import { io } from 'socket.io-client';
import { auth } from './auth/AuthService';
import { DIRECT_QUEST_ID, DIRECT_QUEST_ID_URL_PARAM } from './consts/params';

const SocketInitializer = () => {
  const {
    dispatch,
    state: { user, socket },
  } = useContext(AppContext);

  useEffect(() => {
    if (user && !socket) {
      const questId = new URLSearchParams(window.location.search).get(
        DIRECT_QUEST_ID_URL_PARAM
      );
      const options = {
        transports: ['websocket'],
        query: { token: auth.getAccessToken() },
      };
      if (questId) {
        options.query[DIRECT_QUEST_ID] = questId;
      }
      const newSocket = io(process.env.REACT_APP_ENGINE_URL, options);

      dispatch({
        type: SOCKET_ACTIONS.SET_SOCKET,
        payload: { socket: newSocket },
      });
    }
  }, [user, dispatch, socket]);

  return null;
};

export default SocketInitializer;
