export const DIRECT_QUEST_ID = 'direct-quest-id';
export const DIRECT_QUEST_ID_URL_PARAM = 'directQuestId';
export const GUEST_USER_ID_URL_PARAM = 'guest_user_id';

export const FORCE_GUEST_URL_PARAM = 'forceGuest';
export const EXTERNAL_USER_ID = 'external_user_id';
export const REFERRER_URL_PARAM = 'referrer';
export const COMPANY_ID_URL_PARAM = 'company_id';
export const COMPANY_NAME_URL_PARAM = 'company_name';
export const REDIRECT_URL_PARAM = 'redirect';
export const JOURNEY_ID_URL_PARAM = 'journey_id';
export const QUEST_PRIMARY_ID_URL_PARAM = 'quest_primary_id';
export const USEREMAIL_URL_PARAM = 'user_email';

export const START_QUEST = 'start-quest';
