/* eslint-disable @typescript-eslint/no-explicit-any */

import axiosLib, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { auth } from '../auth/AuthService';
import { DIRECT_QUEST_ID, DIRECT_QUEST_ID_URL_PARAM } from '../consts/params';
import axios from 'axios';
import { Group } from './types/group';
import UserJourney from './types/userJourney';
import Journey, { AdminDashboardJourney } from './types/journey';
import UserQuest from './types/userQuest';
import Certificate from './types/certificate';
import UserChallenge from './types/userChallenge';
import UserAchievement from './types/userAchievement';
import GroupUsersSummary from './types/groupUsersSummary';
import UserCatalogQuest from './types/userCatalogQuest';
import {
  AdminDashboardOverview,
  LeaderboardUser,
  UserScoreCardAnalytics,
} from './types/companyAdmin';
import { UserProfile } from './types/user';
import Quest, { AdminQuestsDashboardResponse } from './types/quest';

const baseURL = process.env.REACT_APP_ENGINE_URL;

interface EngineClientRequestConfig extends AxiosRequestConfig {
  _isRetryRequest?: boolean;
}

type Payload = {
  [key: string]: any;
};

export default class EngineClient {
  protected axios: AxiosInstance;

  constructor() {
    this.axios = axiosLib.create({
      baseURL: `${baseURL}/api/v1`,
      headers: {
        'Content-type': 'application/json',
      },
    });

    this.setRequestInterceptors();
    this.setResponseInterceptors();
  }

  setRequestInterceptors() {
    this.axios.interceptors.request.use((config: EngineClientRequestConfig) => {
      const directQuestId: string | null = new URLSearchParams(
        window.location.search
      ).get(DIRECT_QUEST_ID_URL_PARAM);
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${auth.getAccessToken()}`;
      if (directQuestId) {
        config.headers[DIRECT_QUEST_ID] = directQuestId;
      }
      return config;
    });
  }

  async errorHandler(error: unknown) {
    if (!axios.isAxiosError(error)) {
      return Promise.reject(error);
    }

    const config = error.config as EngineClientRequestConfig;

    if (
      error?.response?.status === 401 &&
      auth.getAccessToken() &&
      !config._isRetryRequest
    ) {
      try {
        config._isRetryRequest = true;
        await auth.verifyLoginSessionActive();
        return this.axios.request(config);
      } catch (err) {
        if (
          !axios.isAxiosError(err) ||
          (err.code === 'login_required' &&
            window.location.pathname !== '/signout')
        ) {
          window.location.assign('/signout');
        }
      }
    }

    return Promise.reject(error);
  }

  setResponseInterceptors() {
    this.axios.interceptors.response.use(
      response => response,
      async error => {
        return this.errorHandler(error);
      }
    );
  }

  async getCompanyByName(companyName: string) {
    const res = await this.axios.get(`/companies?name=${companyName}`);
    return res.data;
  }

  get = async <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R> => this.axios.get(url, config);

  post = async <T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R> => this.axios.post(url, data, config);

  async getCompanyById(companyId: string) {
    const res = await this.axios.get(`/companies?id=${companyId}`);
    return res.data;
  }

  async getUser() {
    const res = await this.axios.get(`/users/me`);
    return res.data;
  }

  async getPage(slug: string) {
    const res = await this.axios.get(`/page/${slug}`);
    return res.data;
  }

  async createUser(user: string) {
    const res = await this.axios.post(`/users`, JSON.stringify(user));
    return res.data;
  }

  async updateUser(body: Payload) {
    return this.axios.put(`/users/me`, JSON.stringify(body));
  }

  async setCompanySkills(comapnyName: string, skills: string[]) {
    return await this.axios.put(
      `/companies/${comapnyName}/skills`,
      JSON.stringify({ skills })
    );
  }

  async resendVerificationEmail(email: string) {
    return this.axios.post(`/auth/verification`, JSON.stringify({ email }));
  }

  async trackUserEvent(event: Payload, props: Payload = {}) {
    return this.axios.post(
      `/users/me/events`,
      JSON.stringify({ eventName: event, ...props })
    );
  }

  async sendQuestEvent(userId: string, body: Payload) {
    return this.axios.post(
      `${baseURL}/users/${userId}/event`,
      JSON.stringify(body)
    );
  }

  async getUserActiveQuests() {
    const res = await this.axios.get(`/users/me/quests`, {
      params: { active: true },
    });
    return res.data;
  }

  async getQuestsForCatalog(): Promise<UserCatalogQuest[]> {
    const res = await this.axios.get(`/users/me/catalog`);
    return res.data;
  }

  async getUserQuests(withCertificates = false): Promise<UserQuest[]> {
    const res = await this.axios.get(
      `/users/me/quests?withCertificates=${withCertificates}`
    );
    return res.data;
  }

  async getQuest(questId: string) {
    const res = await this.axios.get(`/quests/${questId}`);
    return res.data;
  }

  async getFeaturedWelcomeQuests(): Promise<Quest[]> {
    const res = await this.axios.get(`/quests/catalog/featuredWelcomeQuests`);
    return res.data;
  }

  async getQuestByPrimaryOrId(questPrimaryOrId: string) {
    const res = await this.axios.get(`/quests/questData/${questPrimaryOrId}`);
    return res.data;
  }

  async getQuestsSocialProof() {
    const res = await this.axios.get(`/quests/socialProof`);
    return res.data;
  }

  async getCompanyJourneyDataById(companyName: string, journeyId: string) {
    const res = await this.axios.get(
      `/journeys/${journeyId}/company/${companyName}`
    );
    return res.data;
  }

  async getJourneyDataById(journeyId: string): Promise<Journey> {
    const res = await this.axios.get(`/journeys/${journeyId}`);
    return res.data;
  }

  async getAllUserJourneys(): Promise<UserJourney[]> {
    const res = await this.axios.get(`/users/me/journeys`);
    return res.data;
  }

  async getCatalogJourneys(): Promise<Journey[]> {
    const res = await this.axios.get(`/users/me/journeys/catalog`);
    return res.data;
  }

  async getUserJourneyById(journeyId: string) {
    const res = await this.axios.get(`/users/me/journeys/${journeyId}`);
    return res.data;
  }

  async joinUserJourney(journeyId: string) {
    const res = await this.axios.put(
      `/users/me/journeys/${journeyId}/join`,
      {}
    );
    return res.data;
  }

  async getJourneyNextQuest(): Promise<Quest> {
    const res = await this.axios.get(`/users/me/journeys/nextQuest`);
    return res.data;
  }

  async getUserAchievements(): Promise<UserAchievement[]> {
    const res = await this.axios.get(`/users/me/achievements`);
    return res.data;
  }

  async getChallengesWithUserStatus(): Promise<UserChallenge[]> {
    const res = await this.axios.get(`/users/me/challenges`);
    return res.data;
  }

  async optInChallenge(challenge: string) {
    const res = await this.axios.patch(
      `/users/me/challenges/${challenge}/opt-in`
    );
    return res.data;
  }

  async optOutChallenge(challenge: string) {
    const res = await this.axios.patch(
      `/users/me/challenges/${challenge}/opt-out`
    );
    return res.data;
  }

  async getStartingBot(person: string) {
    const queryStr = person ? `?person=${person}` : '';
    const res = await this.axios.get(
      `/users/me/simulations/startingBot${queryStr}`
    );
    return res.data;
  }

  async getQuestStartingBotInfo(questId: string) {
    const res = await this.axios.get(`/quests/${questId}/startingBot`);
    return res.data;
  }

  async startQuest(questId: string, collaborator = null) {
    const res = await this.axios.post(
      `/users/me/quests/${questId}/start`,
      {},
      { params: { collaborator } }
    );
    return res.data;
  }

  async getUserNotifications(active = true) {
    const res = await this.axios.get(`/users/me/notifications`, {
      params: { active: active },
    });
    return res.data;
  }

  async setNotificationSeen(notificationId: string) {
    await this.axios.put(`/users/me/notifications/${notificationId}/ack`);
  }

  async getHintPrice() {
    const res = await this.axios.get(`/credits/hintPrice`);
    return res.data;
  }

  async getQuestCredits(questPrimaryId: string) {
    const res = await this.axios.get(`/credits/quest/${questPrimaryId}`);
    return res.data;
  }

  async getJourneyCredits(journeyId: string) {
    const res = await this.axios.get(`/credits/journey/${journeyId}`);
    return res.data;
  }

  async getSkills() {
    const res = await this.axios.get(`/skills`);
    return res.data;
  }

  async createChatToken() {
    const res = await this.axios.post(`/users/chat/me/token`);
    return res.data;
  }

  async getSidebarTools() {
    const res = await this.axios.get(`/users/chat/me/sidebar/tools`);
    return res.data;
  }

  async createDraftQuest(data: Payload, questId: string) {
    let url = '/editor/quest';
    if (questId) {
      url = `${url}/${questId}`;
    }
    const res = await this.axios.put(url, data);
    return res.data;
  }

  async deleteDraftQuest(questId: string) {
    const url = `/editor/quest/${questId}`;
    const res = await this.axios.delete(url);
    return res.data;
  }

  async getUserAuthoredQuests() {
    const res = await this.axios.get(`/editor/quests`);
    return res.data;
  }

  async generateUserEditorToken() {
    const res = await this.axios.post(`/editor/generateToken`);
    return res.data;
  }

  async submitQuestForReview(draftQuestId: string, isPrivate: boolean) {
    const res = await this.axios.put(
      `/editor/quest/${draftQuestId}/submitForReview`,
      { isPrivate }
    );
    return res.data;
  }

  async removeQuestFromReview(draftQuestId: string) {
    const res = await this.axios.put(
      `/editor/quest/${draftQuestId}/removeFromReview`
    );
    return res.data;
  }

  async publishDraftQuest(draftQuestId: string) {
    const res = await this.axios.put(`/editor/quest/${draftQuestId}/publish`);
    return res.data;
  }

  async getUserGroupSummary(
    companyName: string,
    groupId: string
  ): Promise<GroupUsersSummary> {
    const res = await this.axios.get(
      `/companies/${companyName}/groups/${groupId}/summary`
    );
    return res.data;
  }

  async getCollaborators() {
    const res = await this.axios.get(`/users/me/collaborators`);
    return res.data;
  }

  async getApprovedCollaborators() {
    const res = await this.axios.get(`/users/me/collaborators/approved`);
    return res.data;
  }

  async addCollaborator(email: string) {
    return this.axios.put(`/users/me/collaborators`, JSON.stringify({ email }));
  }

  async removeCollaborator(email: string) {
    await this.axios.delete(`/users/me/collaborators`, { data: { email } });
  }

  async setActiveQuestCollaborator(collaborator: string) {
    await this.axios.put(
      `/users/me/quests/active/collaborator`,
      {},
      { params: { collaborator } }
    );
  }

  async getWizardCollaborators(
    maxReturnedWizards: number,
    excludedEmails = []
  ) {
    const res = await this.axios.get(`/collaborators/wizards`, {
      params: {
        excludedEmails,
        maxReturnedWizards,
        excludeUserCollaborators: true,
      },
    });
    return res.data;
  }

  async sendUserBeacon(body: Payload) {
    await this.axios.post(`/users/beacon`, JSON.stringify(body));
  }

  async getProfile(userId: string) {
    const res = await this.axios.get(`/profile/${userId}`);
    return res.data;
  }

  async getCertificate(certificateId: string): Promise<Certificate> {
    const res = await this.axios.get(`/certificate/${certificateId}`);
    return res.data;
  }

  async getOrCreateUserQuestCertificate(
    primaryId: string
  ): Promise<Certificate> {
    const res = await this.axios.put(
      `/users/me/certificate/quest/${primaryId}`
    );
    return res.data;
  }

  async getUserJourneyCertificate(journeyId: string): Promise<Certificate> {
    const res = await this.axios.get(
      `/users/me/certificate/journey/${journeyId}`
    );
    return res.data;
  }

  async createUserJourneyCertificate(journeyId: string): Promise<Certificate> {
    const res = await this.axios.put(
      `/users/me/certificate/journey/${journeyId}`
    );
    return res.data;
  }

  async uploadProfileImage(data: Payload) {
    const res = await this.axios.put('/profile/me/profileImage', data);
    return res.data;
  }

  async getKubeConfig() {
    const res = await this.axios.get(`/kubernetes/kubeconfig`);
    return res.data;
  }

  async getInReviewQuests() {
    const res = await this.axios.get(`/quests/inReview`);
    return res.data;
  }

  async getNextQuests(journeyId: string | null = null): Promise<Quest[]> {
    const res = await this.axios.get(`/users/me/nextAvailableQuests`, {
      params: { journeyId },
    });
    return res.data;
  }

  async getUserlQusetByQuestId(id: string) {
    const res = await this.axios.get(`/users/me/quests/${id}`);
    return res.data;
  }

  async getQuestDiff(questId: string) {
    const res = await this.axios.get(`/editor/quest/${questId}/diff`);
    return res.data;
  }

  async createAuth0GuestUser() {
    const res = await this.axios.post(`/auth/guestUser`);
    return res.data;
  }

  async getAnonymousUser(externalUserId: string, company: string) {
    const res = await this.axios.post(
      `/users/anonymous/company/${company}/${externalUserId}`,
      {}
    );
    return res.data;
  }

  async getUserGroups(): Promise<Group[]> {
    const res = await this.axios.get(`/users/me/groups`);
    return res.data;
  }

  async getAdminDashboardJourneys(
    companyId: string
  ): Promise<AdminDashboardJourney[]> {
    const res = await this.axios.get(`/company/${companyId}/admin/journeys`);
    return res.data;
  }

  async getAdminDashboardJourney(
    companyId: string,
    journeyId: string
  ): Promise<AdminDashboardJourney[]> {
    const res = await this.axios.get(
      `/company/${companyId}/admin/journeys/${journeyId}`
    );
    return res.data;
  }

  async getAdminDashboardQuests(
    companyId: string
  ): Promise<AdminQuestsDashboardResponse> {
    const res = await this.axios.get(`/company/${companyId}/admin/quests`);
    return res.data;
  }

  async getAdminDashboardOverview(
    companyId: string
  ): Promise<AdminDashboardOverview> {
    const res = await this.axios.get(`/company/${companyId}/admin/overview`);
    return res.data;
  }

  async getAdminDashboardUserProfile(
    userId: string,
    companyId: string
  ): Promise<UserProfile> {
    const res = await this.axios.get(
      `/company/${companyId}/admin/users/${userId}`
    );
    return res.data;
  }

  async getAdminDashboardUserAnalytics(
    userId: string,
    companyId: string
  ): Promise<UserScoreCardAnalytics> {
    const res = await this.axios.get(
      `/company/${companyId}/admin/users/${userId}/analytics`
    );
    return res.data;
  }

  async getAdminDashboardUserActivity(userId: string, companyId: string) {
    const res = await this.axios.get(
      `/company/${companyId}/admin/users/${userId}/activity`
    );
    return res.data;
  }

  async getAdminDashboardLeaderboard(companyId: string): Promise<{
    [groupName: string]: LeaderboardUser[];
  }> {
    const res = await this.axios.get(`/company/${companyId}/admin/leaderboard`);
    return res.data;
  }

  async getAdminDashboardQuestLeaderboard(
    companyId: string,
    questPrimaryId: string
  ): Promise<{
    [groupName: string]: LeaderboardUser[];
  }> {
    const res = await this.axios.get(
      `/company/${companyId}/admin/leaderboard/quest/${questPrimaryId}`
    );
    return res.data;
  }

  async getAdminDashboardJourneyLeaderboard(
    companyId: string,
    journeyId: string
  ): Promise<{
    [groupName: string]: LeaderboardUser[];
  }> {
    const res = await this.axios.get(
      `/company/${companyId}/admin/leaderboard/journey/${journeyId}`
    );
    return res.data;
  }

  async getAdminDashboardUserJourneys(
    userId: string,
    companyId: string
  ): Promise<UserJourney[]> {
    const res = await this.axios.get(
      `/company/${companyId}/admin/users/${userId}/userJourneys`
    );
    return res.data;
  }

  async getVoteOnQuest(questPrimaryId: string) {
    const res = await this.axios.get(`/users/me/votes/quest/${questPrimaryId}`);
    return res.data;
  }

  async getVoteOnJourney(journeyId: string) {
    const res = await this.axios.get(`/users/me/votes/journey/${journeyId}`);
    return res.data;
  }

  async setVoteOnQuest(quesPrimaryId: string, vote: number) {
    const res = await this.axios.put(`/users/me/votes/quest/${quesPrimaryId}`, {
      vote,
    });
    return res.data;
  }

  async addUserToCompany(companyName: string) {
    const res = await this.axios.put(`/users/me/company/${companyName}`);
    return res.data;
  }

  async getCompanyQuestsIds(companyName: string) {
    const res = await this.axios.get(
      `/companies/${companyName}/companyQuestsIds`
    );
    return res.data;
  }

  async connectGithubApp(code: string) {
    const res = await this.axios.post(`/users/me/github/connect`, { code });
    return res.data;
  }

  async connectWixApp(code: string) {
    const res = await this.axios.post(`/users/me/wix/connect`, { code });
    return res.data;
  }

  async connectWixUser(userId: string) {
    const res = await this.axios.post(`/users/me/wix/connect`, { userId });
    return res.data;
  }

  async myCodespace() {
    const res = await this.axios.get(`/users/me/codespace`);
    return res.data;
  }

  async myCodespaceStatus() {
    const res = await this.axios.get(`/users/me/codespace/status`);
    return res.data;
  }

  async setBrowserUrl(url: string) {
    const res = await this.axios.put('/browser/url', {
      url,
    });
    return res.data;
  }

  async getOrCreateUserQuestFeedback(primaryId: string): Promise<Certificate> {
    const res = await this.axios.put(`/users/me/feedback/quest/${primaryId}`);
    return res.data;
  }

  async getQuestHintsUsed(primaryId: string) {
    const res = await this.axios.get(`/users/me/hints/quest/${primaryId}/used`);
    return res.data;
  }

  async healthcheck() {
    const res = await this.axios.get(`/health`);
    return res.data;
  }

  async systemCheck() {
    const res = await this.axios.get(`/health/system`);
    return res.data;
  }
}
