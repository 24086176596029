import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import engineClient from '../../engine-client';
import {
  GeneratedQuestSessionResponse,
  GeneratedQuestResponseWithId,
  GenerateQuestRequest,
  GeneratedQuestResponse,
  GeneratedQuestDownload,
} from './types';
import { generateToast } from '../../../admin/components/userInfo/github/utils';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';

export const QUERIES_KEYS = {
  GENERATED_QUESTS: 'generatedQuests',
};

const updateQuestsOnSuccess = (
  newQuest: GeneratedQuestSessionResponse,
  queryClient: QueryClient
) => {
  queryClient.setQueryData(
    'generatedQuests',
    (
      oldQuests: GeneratedQuestSessionResponse[] | undefined
    ): GeneratedQuestSessionResponse[] => {
      return oldQuests ? [newQuest, ...oldQuests] : [newQuest];
    }
  );
};

export function useGenerateQuestMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (generateQuestRequest: GenerateQuestRequest) =>
      engineClient.post<GeneratedQuestSessionResponse>(
        '/genai/quests',
        generateQuestRequest
      ),
    onSuccess: result => updateQuestsOnSuccess(result.data, queryClient),
  });
}

export function useGeneratedQuestsQuery() {
  return useQuery<GeneratedQuestResponseWithId[]>({
    queryKey: QUERIES_KEYS.GENERATED_QUESTS,
    queryFn: async () => {
      const { data } = await engineClient.get<GeneratedQuestResponseWithId[]>(
        '/genai/quests',
        {
          params: {
            limit: 10,
          },
        }
      );
      return data;
    },
  });
}

export function useGeneratedQuestDetailsQuery(id: string, enabled = true) {
  return useQuery<GeneratedQuestResponse>({
    queryKey: ['generatedQuest', id],
    queryFn: async () => {
      const { data } = await engineClient.get<GeneratedQuestResponse>(
        `/genai/quests/${id}`
      );
      return data;
    },
    enabled: enabled,
  });
}

const errorToast = (error: string) => {
  return {
    title: 'Error Downloading Quest',
    description: error,
    status: 'error',
    duration: 5000,
    isClosable: true,
  };
};

export function useGeneratedQuestDownloadQuery(id: string) {
  const toast = useToast();

  return useQuery<GeneratedQuestDownload, AxiosError>({
    queryKey: ['generatedQuestDownload', id],
    queryFn: async () => {
      const { data } = await engineClient.get(`/quests/${id}/download`);
      return data;
    },
    enabled: false,
    onError: (error: AxiosError) => {
      generateToast(toast, errorToast(error.message));
    },
  });
}
