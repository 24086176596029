import { AppContext, USER_AUTH_LIFECYCLE_ACTIONS } from './appContext';

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { auth } from './auth/AuthService';
import { useRollbar } from '@rollbar/react';
import engineClient from './wilco-engine/engine-client';

const REFRESH_TOKENS_INTERVAL_MILISECONDS = 15 * 60 * 1000;

async function fetchUser() {
  try {
    return await engineClient.getUser();
  } catch (err) {
    return null;
  }
}

async function loadUserInfoToAppState(dispatch) {
  const user = await fetchUser();

  if (user) {
    dispatch({
      type: USER_AUTH_LIFECYCLE_ACTIONS.SET_USER,
      payload: { user },
    });

    return true;
  }

  return false;
}

async function verifyLoginSessionActive(dispatch, rollbar, loadUserInfo) {
  try {
    await auth.verifyLoginSessionActive();
    return loadUserInfo ? await loadUserInfoToAppState(dispatch) : true;
  } catch (err) {
    if (err.code === 'login_required') {
      dispatch({ type: USER_AUTH_LIFECYCLE_ACTIONS.CLEAR_USER });
      return false;
    } else {
      //Assume active session until we explicitly know new login is required
      rollbar.warn(`Check User session activity failed`, err);
      return !loadUserInfo;
    }
  }
}

function setVerifyLoginSessionInterval(dispatch, rollbar) {
  //Refresh Auth session once in X minutes (according to Auth0 best practices)
  return setInterval(() => {
    verifyLoginSessionActive(dispatch, rollbar, false);
  }, REFRESH_TOKENS_INTERVAL_MILISECONDS);
}

function resetVerifyLoginSessionInterval(prevIntervalId, dispatch, rollbar) {
  clearInterval(prevIntervalId);
  return setVerifyLoginSessionInterval(dispatch, rollbar);
}

const LOAD_LOGIN_SESSION_PROCESS_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
};

const AuthSessionVerifier = ({ children }) => {
  const { dispatch } = useContext(AppContext);
  const rollbar = useRollbar();
  const [loadLoginSessionProcessStatus, setLoadLoginSessionProcessStatus] =
    useState(LOAD_LOGIN_SESSION_PROCESS_STATUS.NOT_STARTED);

  const verifyLoginSessionIntervalId = useRef(null);

  const loadLoginSession = useCallback(async () => {
    if (
      loadLoginSessionProcessStatus !==
      LOAD_LOGIN_SESSION_PROCESS_STATUS.NOT_STARTED
    ) {
      return;
    }
    setLoadLoginSessionProcessStatus(LOAD_LOGIN_SESSION_PROCESS_STATUS.STARTED);

    const hasActiveLoginSession = await verifyLoginSessionActive(
      dispatch,
      rollbar,
      true
    );

    if (hasActiveLoginSession) {
      verifyLoginSessionIntervalId.current = setVerifyLoginSessionInterval(
        dispatch,
        rollbar
      );
    }

    setLoadLoginSessionProcessStatus(
      LOAD_LOGIN_SESSION_PROCESS_STATUS.FINISHED
    );
  }, [dispatch, rollbar, loadLoginSessionProcessStatus]);

  useEffect(() => {
    if (verifyLoginSessionIntervalId.current) {
      verifyLoginSessionIntervalId.current = resetVerifyLoginSessionInterval(
        verifyLoginSessionIntervalId.current,
        dispatch,
        rollbar
      );
    }
  }, [dispatch, rollbar]);

  useEffect(() => {
    loadLoginSession();
  }, [loadLoginSession]);

  return loadLoginSessionProcessStatus ===
    LOAD_LOGIN_SESSION_PROCESS_STATUS.FINISHED
    ? children
    : null;
};

export default AuthSessionVerifier;
