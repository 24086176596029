import { useQuery } from 'react-query';
import engineClient from '../engine-client';

export const QUEST_QUERIES_KEYS = {
  CATALOG_QUESTS: ['catalogQuests'],
};

export function useUserQuestByQuestIdQuery(questId) {
  return useQuery(['userQuestByQuestId', questId], async () => {
    if (!questId) return null;

    const quest = await engineClient.getUserQusetByQuestId(questId);
    return quest;
  });
}

export function useCatalogQuests() {
  return useQuery(QUEST_QUERIES_KEYS.CATALOG_QUESTS, async () => {
    const quests = await engineClient.getQuestsForCatalog();
    return quests;
  });
}

export function useQuestByPrimaryOrQuestIdQuery(questPrimaryOrId) {
  return useQuery(['questByPrimaryOrQuestId', questPrimaryOrId], async () => {
    return await engineClient.getQuestByPrimaryOrId(questPrimaryOrId);
  });
}

export function useJourneyByCompanyAndIdQuery(company, journey) {
  return useQuery(['journeyByCompanyAndId', journey, company], async () => {
    if (!company || !journey) return null;
    return await engineClient.getCompanyJourneyDataById(company, journey);
  });
}

export function useQuestCredits(questPrimaryId) {
  return useQuery(['questCredits', questPrimaryId], async () => {
    const questCredits = await engineClient.getQuestCredits(questPrimaryId);
    return questCredits;
  });
}

export function useQuestHintsUsed(questPrimaryId) {
  return useQuery(['questHintsUsed', questPrimaryId], async () => {
    const questCredits = await engineClient.getQuestHintsUsed(questPrimaryId);
    return questCredits;
  });
}

export function useCompanyQuestsIdsQuery(companyName) {
  return useQuery(['companyQuestsIds', companyName], async () => {
    const questsIds = await engineClient.getCompanyQuestsIds(companyName);
    return questsIds;
  });
}

export function useGetCatalogFeaturedWelcomeQuestsQuery() {
  return useQuery(['featuredWelcomeQuests'], async () => {
    const quests = await engineClient.getFeaturedWelcomeQuests();
    return quests;
  });
}
